import gsap from "gsap";
import Splide from '@splidejs/splide';
import "../style/style.scss";
import '@splidejs/splide/css';
import '@splidejs/splide/css/core';


//VARIABLES

const 
body = document.querySelector("body"),
burger = document.getElementById("burger"),
navigation = document.getElementById("navigation"),
navigation_links = document.querySelectorAll("nav a"),
domaine_cartons_bitume = document.getElementById("domaine-cartons-bitume"),
carousel_cartons_bitumes = document.getElementById("carousel-cartons-bitumes"),
domaine_resines = document.getElementById("domaine-resines"),
carousel_resines = document.getElementById("carousel-resines"),
domaine_cartons_carbonatation = document.getElementById("domaine-carbonatation"),
carousel_carbonatation = document.getElementById("carousel-carbonatation"),
domaine_joints_injections = document.getElementById("domaine-joints-injections"),
carousel_joints_injections = document.getElementById("carousel-joints-injections"),
carousels = document.getElementById("carousels"),
carousel_Xmark = document.getElementById("carousel-xMark"),
carousel_options ={
  type   : 'loop',
  perPage: 1,
  cover:true,
  height:"70vh",
  gap:"20rem",
  arrowPath:"M39.17,17.65c1.12,1.3,1.12,3.41,0,4.72l-14.29,16.66c-1.12,1.3-2.93,1.3-4.05,0s-1.12-3.41,0-4.72l9.42-10.98H2.86C1.28,23.33,0,21.84,0,20c0-1.84,1.28-3.33,2.86-3.33H30.25L20.84,5.69c-1.12-1.3-1.12-3.41,0-4.72,1.12-1.3,2.93-1.3,4.05,0l14.29,16.66h0Z",
  classes: {
    arrows: 'splide__arrows carousel-arrows',
    arrow : 'splide__arrow carousel-arrow',
  },
  pagination:false,
  mediaQuery: 'min',
  breakpoints:{
    900:{
        cover:false,
        height:"100%",
        fixedHeight:"80vh",
        keyboard:true
    }
}

};

let
isSmartphone = window.innerWidth<900,
menustate = false;

//EVENT LISTENER

window.addEventListener("resize",()=>{
  isSmartphone = window.innerWidth<900;
})

burger.addEventListener("click",()=>{
    menustate && burgerToStack();
    !menustate && burgerToCross();
    menustate = !menustate;
});

domaine_cartons_bitume.addEventListener( 'click', function() {
  openCarousel(carousel_cartons_bitumes);
  var splide = new Splide( carousel_cartons_bitumes, carousel_options );
  splide.mount();
  carousel_Xmark.addEventListener("click", ()=>closeCarousel(splide,carousel_cartons_bitumes));
});

domaine_resines.addEventListener( 'click', function() {
  openCarousel(carousel_resines);
  var splide = new Splide( carousel_resines, carousel_options );
  splide.mount();
  carousel_Xmark.addEventListener("click", ()=>closeCarousel(splide,carousel_resines));
});

domaine_cartons_carbonatation.addEventListener( 'click', function() {
  openCarousel(carousel_carbonatation);
  var splide = new Splide( carousel_carbonatation, carousel_options );
  splide.mount();
  carousel_Xmark.addEventListener("click", ()=>closeCarousel(splide,carousel_carbonatation));
});

domaine_joints_injections.addEventListener( 'click', function() {
  openCarousel(carousel_joints_injections);
  var splide = new Splide( carousel_joints_injections, carousel_options );
  splide.mount();
  carousel_Xmark.addEventListener("click", ()=>closeCarousel(splide,carousel_joints_injections));
});

navigation_links.forEach((el)=>{
  el.addEventListener("click",()=>{
    console.log(isSmartphone);
    if(!isSmartphone) return;
    burgerToStack()
  });
});

//FUNCTIONS

const burgerToCross = ()=>{
    body.style.overflow = "hidden";
    const lines = burger.querySelectorAll("line");
    gsap.to(lines[1], { attr: { x1: 20 } });
    gsap.to(lines[0], { delay:0.15,rotate:45, attr: { x2: 57 } });
    gsap.to(lines[1], { delay:0.2, autoAlpha: 0 });
    gsap.to(lines[2], { delay:0.15, rotate:-45, attr: { x2: 57 } });
    gsap.to(navigation,{autoAlpha:1, y:0})
};

const burgerToStack = ()=>{
    body.style.overflow = "initial";
    const lines = burger.querySelectorAll("line");
    gsap.to(lines[0], { rotate:0, attr: { x2: 55 } });
    gsap.to(lines[1], { autoAlpha: 1 });
    gsap.to(lines[2], { rotate:0, attr: { x2: 55 } });
    gsap.to(lines[1], { attr: { x1: 38 } });
    gsap.to(navigation,{autoAlpha:0, y:"-100%"})
};

const openCarousel = (el)=>{
  body.style.overflow = "hidden";
  gsap.to(el,{autoAlpha:1})
  carousels.style.display = "block";
};

const closeCarousel = (instance,el)=>{
  body.style.overflow = "initial";
  carousels.style.display = "none";
  gsap.to(el,{autoAlpha:0});
  instance.destroy();
  // Supprime l'événement après l'avoir exécuté
  carousel_Xmark.removeEventListener("click", closeCarousel);
};

async function initMap() {
  // Request needed libraries.
  const { Map } = await google.maps.importLibrary("maps");
  const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
  const map = new Map(document.getElementById("map"), {
      center: { lat: 46.07495, lng: 7.06995 },
      zoom: 18,
      disableDefaultUI: true,
      mapId: "4504f8b37365c3d0",
  });
  const marker = new AdvancedMarkerElement({
    map,
    position: { lat: 46.07495, lng: 7.06995 },
  });
}
  
initMap();

